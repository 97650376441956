.notificationButtonNavbar{
  display: flex;
  margin-right: 1rem;
  height: 100%;
  width: 1.5rem;
}

.notificationButtonNavbar.divDark{
  background: #656565;
}

.notificationButtonNavbar.divLight{
  background: #fdfdfd;
}

.notificationButton, .cardPopoverNotification button{
  background: transparent;
  border: 0;
  color: black;
}

.popup-arrow {
  color: black;
}

.cardPopoverNotification{
  width: 15rem;
  height: 10rem;
  border: 1px solid black;
  border-radius: 0.5rem;
  padding: 0.5rem;
  align-items: center;
  align-content: center;
  text-align: center;
  z-index: 1000;
  background: #e7e7e7;
  overflow-y: scroll;
}

.cardPopoverNotification{
  overflow: overlay;
}
.cardPopoverNotification.scrollDark{
  background: #3B3B3B;
}

.cardPopoverNotification tr{
  text-align: left;
  border-bottom: 1px solid;
}

.cardPopoverNotification::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.cardPopoverNotification::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
.cardPopoverNotification::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

.cardPopoverNotification.scrollDark::-webkit-scrollbar-thumb {
  background: #414141; 
}

/* Handle on hover */
.celulaMarcacao.scrollDark::-webkit-scrollbar-thumb:hover {
  background: #313030; 
}

/* Handle */
.cardPopoverNotification.scrollLight::-webkit-scrollbar-thumb {
  background: #bbbbbb; 
  border-radius: 10px;
}

/* Handle on hover */
.cardPopoverNotification.scrollLight::-webkit-scrollbar-thumb:hover {
  background: #929292; 
}