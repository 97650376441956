#svg-map { min-width: 300px; }

#svg-map path.active { fill:#0094d9 }

#svg-map path.inactive { fill:#CCC }

#svg-map text { fill:#fff; font:12px Arial-BoldMT, sans-serif; cursor:pointer }

#svg-map a{ text-decoration:none }

#svg-map a:hover { cursor:pointer; text-decoration:none }

#svg-map a:hover path{ fill:#1cad3c !important }

#svg-map .circle { fill:#66ccff }

#svg-map a:hover .circle { fill:#003399 !important; cursor:pointer }